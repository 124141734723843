.App {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Ensure that the header content wraps to the next line if needed */
  }
  
  .Title {
    margin: 0;
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: 5vw; /* Set font size using viewport units */
  }
  
  .Nav {
    display: flex;
    flex-wrap: wrap;
  }
  
  .Nav a {
    margin-right: 20px;
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .Nav a:hover {
    color: #007bff;
  }
  
  /* Adjust layout for smaller screens */
  @media screen and (max-width: 600px) {
    .Header {
      flex-direction: column; /* Change to a column layout on smaller screens */
      align-items: flex-start; /* Align items to the start of the column */
    }
  
    .Nav {
      margin-top: 20px; /* Add some space between company name and navigation links */
      font-size: 0.9rem;
    }
  }
  
  /* Limit maximum font size for larger screens */
  @media screen and (min-width: 768px) {
    .Title {
      font-size: 2.5rem; /* Adjust font size as needed for larger screens */
    }
  }
  